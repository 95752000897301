jQuery(function ($) {
  // Test if the infinite scroll div is in the page
  if ($(".kl_testimonials_infinite_scroll").length == 0) {
    return;
  }

  var canBeLoaded = true;

  $(window).live("scroll", function () {
    var data = {
      action: "kl_testimonials_loadmore",
      query: $(
        ".kl_testimonials_infinite_scroll, .kl_testimonials_loadmore"
      ).attr("data-query"),
      page: kl_testimonials_loadmore_params.current_page,
      theme: $(
        ".kl_testimonials_infinite_scroll, .kl_testimonials_loadmore"
      ).attr("data-theme"),
	};

    if (
      isScrolledIntoView($(".kl_testimonials_infinite_scroll")) &&
      canBeLoaded == true
    ) {
      $.ajax({
        url: kl_testimonials_loadmore_params.ajaxurl,
        data: data,
        type: "POST",
        beforeSend: function (xhr) {
          canBeLoaded = false;
          $(".kl_testimonials_infinite_scroll").append(
            '<div class="spinner"></div>'
          );
        },
        success: function (data) {
          if (data) {
            $(".kl_testimonials_infinite_scroll").prev().after(data);
            $(".spinner").remove();

            canBeLoaded = true;
            kl_testimonials_loadmore_params.current_page++;
            if (
              kl_testimonials_loadmore_params.current_page ==
              kl_testimonials_loadmore_params.max_page
            ) {
              $(".kl_testimonials_infinite_scroll").remove();
            }
          } else {
            $(".kl_testimonials_infinite_scroll").remove();
          }
        },
      });
    }
  });

  function isScrolledIntoView(elem) {
    if ($(".kl_testimonials_infinite_scroll").length == 0) {
      return;
    }

    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }
});
