jQuery(function ($) {
  $(".kl_testimonials-header__slick").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    adaptiveHeight: true,
	swipeToSlide: true,
	fade: true,
    prevArrow: $(".carousel-prev-arrow"),
	nextArrow: $(".carousel-next-arrow"),
	asNavFor: '.kl_testimonials-content__slick'
  });

  $(".kl_testimonials-content__slick").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    adaptiveHeight: true,
	swipeToSlide: true,
	arrows: false,
	asNavFor: '.kl_testimonials-header__slick'
  });
});
