jQuery(function ($) {
  if (!$(".kl_testimonials_form").length) {
    return;
  }

  $(".kl_testimonials_form").submit(function (e) {
	e.preventDefault();
	let form = $('.kl_testimonials_form');
	let data = new FormData($(this)[0]);
	let that = $(this);
	let button_text = $('.kl_testimonials_button').text();

    $.ajax({
      url: kl_testimonials.ajax_url,
      method: "POST",
      data: data,
      contentType: false,
	  processData: false,
	  beforeSend: function() {
		  $('.kl_testimonials_button').text(kl_testimonials.loading);
	  },
      success: function (response) {
		  $('.kl_testimonials_button').text(button_text);
		  if(response.success) {
			  $('.group-btn').append(
				  `<span class="kl_testimonials_success">${response.data}</span>`
			  );
			  that[0].reset();
		  } else {
			  $('.group-btn').append(
				  `<span class="kl_testimonials_error">${response.data}</span>`
			  );
		  }
      },
      error: function (response) {
		  console.log('e', response);

		  $('.kl_testimonials_button').text(button_text);
		  $('.group-btn').append(
			  `<span class="kl_testimonials_error">${response.data}</span>`
		  );
      },
    });
  });
});
